@tailwind base;
@tailwind components;
@tailwind utilities;

div .prose table{
  text-align: center;
  width: 100%;
  border-collapse: collapse;
}

.prose thead th {
  padding: 0.5rem;
}
.prose tbody td, .prose tbody td:first-child, .prose .shadow-sm td:last-child {
  vertical-align: middle;
  padding: 1rem;
  width: 25%;
}
.prose tbody tr{
  border-bottom-color: black;
  border-bottom-width: 1px;
}
.prose tbody tr:nth-child(even){
  background-color: #f5f5f5;
}
.prose .shadow-sm tr{
  border-bottom-color: black;
  border-bottom-width: 1px;
}

.burst {
  background-image: radial-gradient(
    rgb(37, 53, 230),
    rgba(53, 68, 229, 0.91),
    rgba(69, 82, 229, 0.82),
    rgba(86, 97, 228, 0.73),
    rgba(102, 112, 227, 0.64),
    rgba(118, 127, 226, 0.55),
    rgba(134, 141, 226, 0.45),
    rgba(150, 156, 225, 0.36),
    rgba(166, 171, 224, 0.27),
    rgba(183, 186, 223, 0.18),
    rgba(199, 200, 223, 0.09),
    rgba(215, 215, 222, 0)
  );
}
